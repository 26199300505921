import React from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import { Link } from "gatsby"
import Image from "@components/image"
import Span from "@components/elements/span"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"

const Card = styled(Link)`
  display: inline-grid;
  text-decoration: none;
  overflow: hidden;
  width: 100%;

  :hover {
    h2,
    span {
      color: ${({ theme }) => theme.color.blue400};
    }
  }

  span,
  h2 {
    transition: 0.3s color ease-in-out;
  }
`

const ArticleCard = ({ link, title, summary, image }) => {
  const { color } = useTheme()

  return (
    <Card to={link}>
      <Image
        file={image}
        style={{ maxHeight: "300px", height: "100%" }}
        objectFit="cover"
      />
      <Div margin="2rem 0 0">
        <Heading as="h2" margin="0 0 1rem">
          {title}
        </Heading>
        <Paragraph>
          {summary}
          <Span
            color={color.blue500}
            fontWeight="700"
            display="block"
            margin="1rem 0 0"
          >
            Read more
          </Span>
        </Paragraph>
      </Div>
    </Card>
  )
}

ArticleCard.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default ArticleCard
