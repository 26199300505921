import React from "react"
import styled from "styled-components"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import ArticleCard from "@components/article/card"
import PropTypes from "prop-types"
import Html from "@components/html"
import Heading from "@components/elements/heading"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"

const ArticleSubtitle = styled.div`
  font-size: 180%;
  font-weight: 500;
  color: ${({ theme }) => theme.color.blue600};
  margin-bottom: 3rem;
`

const Articles = ({ data }) => {
  return (
    <Layout seo={data.category.seo}>
      <Div maxWidth="850px" margin="4rem auto">
        <Heading as="h1" fontSize="300%" margin="0 0 1rem">
          {data.category.name}
        </Heading>
        <ArticleSubtitle>
          <Html inherit parseElements html={data.category.description} />
        </ArticleSubtitle>
        <Flex flexFlow="column" gap="4rem">
          {data.articles.edges.map(({ node: article }) => {
            const date = new Date(article.date.replace(/-/g, "/"))
            const dateString = date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })
            return (
              <ArticleCard
                key={article.id}
                image={article.image}
                title={article.seo.title}
                summary={article.seo.description}
                link={article.slug}
                author="Klipfolio"
                date={dateString}
              />
            )
          })}
        </Flex>
      </Div>
    </Layout>
  )
}

Articles.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Articles

export const pageQuery = graphql`
  query ArticleCategoryQuery($id: String!) {
    category: articleCategories(id: { eq: $id }) {
      seo {
        ...seoData
      }
      name
      heading
      description
      id
      slug
      status
    }
    articles: allArticles(
      filter: { status: { eq: "published" }, category: { id: { eq: $id } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          seo {
            ...seoData
          }
          id
          status
          date
          slug
          image {
            id
            title
            cdn
            placeholder
          }
        }
      }
    }
  }
`
